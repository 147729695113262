import React from 'react';
import {PageProps} from 'gatsby';
import Layout from '@/components/Layout';

const NotFound: React.FC<PageProps> = () => (
   <Layout pageTitle="Page Not Found">
      <section className="not-found">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-md-5 col-lg-4 margin-top-l">
                  <h1>404</h1>
                  <h3>Whoops. Looks like what you're looking for can't be found.</h3>
               </div>
            </div>
         </div>
      </section>
   </Layout>
);

export default NotFound;
